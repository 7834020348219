/* tslint:disable */
/* eslint-disable */
/**
 * Poizon Bot Webapp API
 * Poizon Bot Webapp API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReviewImageDto
 */
export interface ReviewImageDto {
    /**
     * 
     * @type {string}
     * @memberof ReviewImageDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewImageDto
     */
    rating: ReviewImageDtoRatingEnum;
}

/**
* @export
* @enum {string}
*/
export type ReviewImageDtoRatingEnum =
    | 'GOOD'
    | 'BAD'
    | 'NORMAL'



export function ReviewImageDtoFromJSON(json: any): ReviewImageDto {
    return ReviewImageDtoFromJSONTyped(json, false);
}

export function ReviewImageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewImageDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'rating': json['rating'],
    };
}

export function ReviewImageDtoToJSON(value?: ReviewImageDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'rating': value.rating,
    };
}

